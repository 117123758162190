<template>
  <div class="content">
    <!-- Start Single Progress Bar -->
    <div class="progress_wrapper">
      <h2 class="section-title text-left">My Skill</h2>
      <div class="single-skill">
        <p>Photoshop</p>
        <div
          class="progress progress-height-6 progress-bar-bg--1 progress-label-top"
        >
          <div
            class="progress-bar wow fadeInLeft progress-bar-bg--1"
            role="progressbar"
            style="width: 100%;"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span class="percent-label">100%</span>
          </div>
        </div>
      </div>

      <div class="single-skill">
        <p>Joomla</p>
        <div
          class="progress progress-height-6 progress-bar-bg--1 progress-label-top"
        >
          <div
            class="progress-bar wow fadeInLeft progress-bar-bg--1"
            role="progressbar"
            style="width: 80%;"
            aria-valuenow="80"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span class="percent-label">80%</span>
          </div>
        </div>
      </div>

      <div class="single-skill">
        <p>Wordpress</p>
        <div
          class="progress progress-height-6 progress-bar-bg--1 progress-label-top"
        >
          <div
            class="progress-bar wow fadeInLeft progress-bar-bg--1"
            role="progressbar"
            style="width: 90%;"
            aria-valuenow="90"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span class="percent-label">90%</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End Single Progress Bar -->
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
